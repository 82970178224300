import React from "react"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

export default function BasicDatePicker({ onChange, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker {...props} onChange={newValue => onChange(newValue._d)} />
    </LocalizationProvider>
  )
}
